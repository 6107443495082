<template>
  <div class="sellPoint-invoice">
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12" lg="9">
          <b-card>
            <!--add header card to serialNum, Date-->
            <b-card-header>
              <div>
                <div class="d-flex mb-2 align-items-center">
                  <h3 class="text-primary pr-2">
                    <strong> E-LEARNING</strong>
                  </h3>
                </div>
              </div>
              <div>
                <ek-input-text
                  v-if="invoicid == 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  :value="sellpointUnpaid.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  v-if="invoicid != 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  v-model="sellPointDto.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  name="dateInvoice"
                  label="تاريخ الفاتورة"
                  :value="formDate(sellPointDto.dateCreated)"
                >
                </ek-input-text>
              </div>
            </b-card-header>

            <hr />
            <!--add Answers -->
            <b-card-body>
              <!--if no code Unpaid-->
              <div
                class="d-flex justify-content-center"
                v-if="sellPointDto.codeIds == ''"
              >
                <h4>لا يوجد أكواد</h4>
              </div>
              <!-- All Code is unPaid-->
              <div
                v-for="(code, index) in sellpointUnpaid.codes"
                :key="index"
                class="d-flex"
              >
                <b-row>
                  <b-col lg="3" cols="3">
                    <b-form-group
                      v-if="invoicid == 0"
                      name="code"
                      label="الكود"
                    >
                      <b-form-input
                        name="code"
                        id="item-name"
                        type="text"
                        plaintext
                        v-model="code.code"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      name="date"
                      label="اسم الحزمة"
                      label-for="date"
                    > 
                    
                    <b-form-input
                        name="code"
                        id="item-name"
                        type="text"
                        plaintext
                        v-model="code.packageName"
                      />
                    </b-form-group>
                  </b-col>


                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      name="date"
                      label="اسم الطالب"
                      label-for="date"
                    > 
                    
                    <b-form-input
                        name="code"
                        id="item-name"
                        type="text"
                        plaintext
                        v-model="code.studentName"
                      />
                    </b-form-group>
                  </b-col>


                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      name="date"
                      label="تاريخ الاشتراك"
                      label-for="date"
                    >
                      <b-form-input
                        name="date"
                        plaintext
                        :value="formDt(code.startDate)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      :disabled="!ready"
                      name="num"
                      label="النسبة "
                    >
                      <b-form-input name="num" v-model="code.rate" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      :disabled="!ready"
                      name="unit"
                      label="الحسم"
                    >
                      <b-form-input name="unit" v-model="code.discount" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid == 0"
                      name="cost"
                      label="الكلفة"
                    >
                      <b-form-input
                        name="cost"
                        plaintext
                        v-model="code.cost"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="1" cols="1" v-if="invoicid == 0">
                    <b-button variant="wight" @click="deleteCode(index)">
                      <svg
                        style="cursor: pointer"
                        width="25"
                        height="25"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                          stroke="#616060"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.875 7.125L7.125 11.875"
                          stroke="#616060"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.125 7.125L11.875 11.875"
                          stroke="#616060"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <!--code is Paid-->
              <div
                v-for="code in sellPointDto.codes"
                :key="code.id"
                class="d-flex"
              >
                <b-row>
                  <b-col lg="3" cols="3">
                    <b-form-group
                      v-if="invoicid != 0"
                      name="code"
                      label="الكود"
                    >
                      <b-form-input
                        name="code"
                        id="item-name"
                        type="text"
                        plaintext
                        v-model="code.generateCode"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="3" cols="3">
                    <b-form-group
                      v-if="invoicid != 0"
                      name="date"
                      label="التاريخ"
                      label-for="date"
                    >
                      <b-form-input
                        name="date"
                        plaintext
                        :value="formDat(code.subscriptionDate)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid != 0"
                      :disabled="!ready"
                      name="num"
                      label="الحسم "
                    >
                      <b-form-input name="num" v-model="code.discount" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid != 0"
                      :disabled="!ready"
                      name="unit"
                      label="النسبة"
                    >
                      <b-form-input name="unit" v-model="code.rate" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" cols="2">
                    <b-form-group
                      v-if="invoicid != 0"
                      name="cost"
                      label="الكلفة"
                    >
                      <b-form-input name="cost" plaintext v-model="code.cost" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
            <b-card-footer>
              <!--add footer to paid , note-->
              <b-row>
                <b-col lg="8" cols="8">
                  {{ cost }}
                  <label>الملاحظات</label>
                  <ek-input-textarea
                    id="note"
                    rows="6"
                    v-model="sellPointDto.note"
                    name="note"
                    placeholder="اضف الملاحظات"
                  >
                  </ek-input-textarea>
                </b-col>
                <b-col lg="4" cols="4">
                  <b-col cols="12">
                    <ul class="list-unstyled p-0 m-0">
                      <li
                        class="
                          d-flex
                          pb-1
                          pt-2
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div>عدد الأكواد</div>
                        <div v-if="invoicid == 0">
                          {{ sellpointUnpaid.codes.length }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ sellPointDto.countCodes }}
                        </div>
                      </li>
                      <li
                        class="
                          d-flex
                          pt-1
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div>الكلفة</div>
                        <div v-if="invoicid == 0">
                          {{
                            sellpointUnpaid.codes.reduce(
                              (pre, cur) => pre + cur.cost,
                              0
                            )
                          }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ sellPointDto.cost }}
                        </div>
                      </li>
                    </ul>
                  </b-col>
                  <b-col cols="12">
                    <hr class="d-none d-lg-block" />
                  </b-col>
                  <b-col cols="12">
                    <ul class="list-unstyled p-0">
                      <li
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div>المستحق للشركة</div>
                        <div v-if="invoicid == 0">
                          {{
                            sellpointUnpaid.codes.reduce(
                              (pre, cur) => pre + cur.companyPaid,
                              0
                            )
                          }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ sellPointDto.companyPaid }}
                        </div>
                      </li>
                    </ul>
                    <div>
                      <b-input-group class="d-flex">
                        <b-input-group-prepend is-text>
                          المدفوع
                        </b-input-group-prepend>
                        <b-form-input
                          type="number"
                          placeholder="المبلغ المدفوع "
                          v-model="sellPointDto.paid"
                        >
                        </b-form-input>
                        <b-input-group-append is-text>
                          ل.س
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col cols="12" lg="3" class="print">
          <!--add button to save , print-->
          <b-card>
            <b-button
              v-if="invoicid == 0"
              variant="primary"
              block
              @click="AddInvoice"
            >
              حفظ
            </b-button>
            <b-button
              v-if="invoicid == 0"
              variant="outline-primary"
              block
              @click="print"
            >
              طباعة
            </b-button>
            <b-button
              v-if="invoicid == 0"
              variant="outline-primary"
              block
              @click="$router.go(-1)"
              >عودة
            </b-button>
            <b-button
              v-if="invoicid != 0"
              variant="primary"
              block
              @click="print"
            >
              طباعة
            </b-button>
            <b-button
              v-if="invoicid != 0"
              variant="outline-primary"
              block
              @click="deleteInvoice(sellPointDto.id)"
            >
              حذف
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
  props: ["invoicid", "id"],
  computed: {
    ...mapState({
      sellpointUnpaid: (state) => state.invoiceSellPoint.sellpointUnpaid,
      sellpointsDetails: ({ invoiceSellPoint }) =>
        invoiceSellPoint.sellpointsDetails,
    }),
  },
  data() {
    return {
      sellPointDto: {
        id: "",
        sellPointId: "",
        serialNumber: "",
        dateCreated: Date.now(),
        codes: [
          {
            id: "",
            cost: 0,
            discount: 0,
            generateCode: "",
            rate: "",
            subscriptionDate: "",
          },
        ],
        codeIds: [" "],
        note: "",
        countCodes: "",
        cost: "",
        balanceDue: "",
        paid: "",
      },
      sellPointList: [],
    };
  },
  methods: {
    ...mapActions([
      "getSellPointDetails",
      "getUnpaidSellPointList",
      "addSellpoint",
      "deleteInvoice",
    ]),
    print() {
      window.print();
    },
    formDate(dateCreated) {
      let date = `${new Date(dateCreated).toLocaleDateString()}`;
      return date;
    },
    formDat(subscriptionDate) {
      let date = `${new Date(subscriptionDate).toLocaleDateString()}`;
      return date;
    },
    formDt(startDate) {
      let date = `${new Date(startDate).toLocaleDateString()}`;
      return date;
    },
    deleteCode(index) {
      this.sellpointUnpaid.splice(index, 1);
      this.sellPointDto.codeIds.splice(index, 1);
      this.sellPointDto.codeCount--;
    },
    AddInvoice() {
      this.$refs.form.validate().then((succ) => {
        if (succ && this.sellpointUnpaid.codes.length > 0) {
          this.addSellpoint(this.sellPointDto).then(
            this.sellPointList.push(this.sellPointDto)
          );
        } else {
          const bToast = new BToast();
          bToast.$bvToast.toast(" لا يمكنك إضافة فاتورة ... لا يوجد اكواد", {
            title: "  لا يوجد اكواد ",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  created() {
    this.sellPointDto.sellPointId = this.id;
    if (this.invoicid != 0) {
      this.getSellPointDetails(this.invoicid).then((res) => {
        this.sellPointDto = { ...res };
      });
    } else {
      this.getUnpaidSellPointList(this.id).then((res) => {
        this.sellPointDto.codeIds = res.codes.map((el) => el.id);
      });
    }
  },
};
</script>
<style >
@media print {
  .print {
    display: none;
  }
  .main-menu{
    display: none !important;
  }
  .app-content{
    margin: 0 !important;
  }
}
</style>